.ShareButton {
	width: 2.25rem;
    height: 2.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
    border-radius: 50%;
	color: #fff;
	background: var(--another-grey-dark-transparent);
	backdrop-filter: blur(10px);
	margin-left: auto;
	margin-bottom: 15px;
	margin-top: -8px;

	&:last-child {
		margin-bottom: 0;
	}

	svg {
		width: 16px;
		height: 16px;
		display: block;
	}
}
