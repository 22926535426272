input, select, textarea {
    outline: 0;
    font-family: var(--fontMain);
    font-size: 14px;
    font-weight: 400;

    @media (max-width: 767px) {
        font-size: 16px;
    }
}

label {
    cursor: pointer;
}

.input {
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	background: var(--another-grey-transparent);
	border: 0;
	padding: 10px 16px;
	min-height: 44px;
	width: 100%;
	font-size: 16px;
	position: relative;
	z-index: 10;
	color: var(--tg-theme-text-color);

	&::placeholder {
		color: var(--tg-theme-hint-color);
	}
}
