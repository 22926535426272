.ProjectsSearch {
	margin-bottom: 15px;
	position: relative;
	border-radius: 10px;
	box-shadow: var(--box-shadow);
	background: var(--another-grey-transparent);

	&-icon {
		width: 16px;
		height: 16px;
		display: block;
		color: var(--tg-theme-hint-color);
		margin-right: 10px;
	}

	&-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		height: 100%;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		width: auto;
		padding: 0 10px;
		color: var(--tg-theme-hint-color);
		transition: 0.3s;
		z-index: 5;

		span {
			display: block;
			padding-top: 1px;
		}
	}

	&-input {
		background: transparent;
		border: 0;
		padding: 0 36px;
		height: 36px;
		width: 100%;
		border-radius: 10px;
		font-size: 16px;
		position: relative;
		z-index: 10;
		color: var(--tg-theme-text-color);

		&::placeholder {
			color: var(--tg-theme-hint-color);
		}

		&:focus {
			& + .ProjectsSearch-placeholder {
				left: 0;
				transform: translateX(0);
			}
		}
	}

	&--filled {
		.ProjectsSearch-placeholder {
			left: 0;
			transform: translateX(0);

			span {
				opacity: 0;
			}
		}
	}
}
