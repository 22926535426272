*, *::before, *::after {
    box-sizing: border-box;
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    height: 100%;
    touch-action: manipulation;
}

body {
    margin: 0 auto;
	max-width: 1920px;
	min-height: 100%;
	width: 100%;
    font-family: var(--fontMain);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: var(--tg-theme-text-color);
    line-height: 1.25;
    background: var(--tg-theme-secondary-bg-color);
	position: relative;
	font-smooth: antialiased;
	-webkit-font-smoothing: antialiased;
}

img {
    max-width: 100%;
    outline: 0;
}

.page404 {
	padding: 50px 0;
}

#root {
	position: relative;
    margin: 0 auto;
	max-width: 480px;
	padding: 16px;
}

