.Header {
	margin-bottom: 15px;
	background: var(--another-grey-transparent);
	padding: 2px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	box-shadow: var(--box-shadow);
	position: relative;

	&::before {
		content: '';
		width: calc(50% - 2px);
		position: absolute;
		background-color: var(--tg-theme-bg-color);
		height: 30px;
		border-radius: 10px;
		top: 2px;
		left: 2px;
		transition: 0.3s;
	}

	&-link {
		position: relative;
		z-index: 10;
		align-items: center;
		background-color: transparent;
		border: none;
		border-radius: inherit;
		box-shadow: none;
		display: flex;
		color: var(--tg-theme-text-color);
		min-width: 50%;
		height: 30px;
		justify-content: center;
		padding: 0 5px;
		text-decoration: none;
	}

	&--active {
		&::before {
			left: 50%;
		}
	}
}

.boxPinned + .Header {
	margin-top: 56px;
}
