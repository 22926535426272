.Alert {
	position: fixed;
	bottom: 16px;
	left: 16px;
	right: 16px;
	height: 40px;
	background: rgba(65, 65, 65, 0.9);
	color: #fff;
	display: flex;
	align-items: center;
	border-radius: 10px;
	padding: 16px;
	backdrop-filter: blur(10px);
	z-index: 1000;

	&Icon {
		min-width: 44px;
		width: 44px;
		height: 44px;
		margin-left: -14px;
	}

	&Text {

	}
}
